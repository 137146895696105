import { useCallback, useEffect, useMemo, useState } from 'react';
import Media from 'react-media';

import { useBasketAddProduct, useGetBasket } from '@api/basket';
import { ProductDetail } from '@api/catalog';

import { useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';

import Counter from '@components/controls/Counter';
import Loader from '@components/controls/Loader';

import { VIEW_OPTIONS } from '@scripts/enums';
import { Button, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';
import { useAddToBasketHandler } from '@scripts/hooks/useAddToBasketHandler';
import { useChangeQty } from '@scripts/hooks/useChangeQty';

const BasketButton = ({
    productItem,
    activeView,
    buttonId,
}: {
    productItem: ProductDetail;
    activeView?: string;
    buttonId: string;
}) => {
    const { sm } = useMedia();

    const { user } = useAuth();

    const { id, quantity } = productItem;

    const { basketProducts } = useCartContext();
    const { isLoading, error } = useBasketAddProduct();
    const { data: apiBasketData, isFetching } = useGetBasket(Boolean(user));

    const [isLoadingBasket, setIsLoadingBasket] = useState(false);

    useEffect(() => {
        if ((!isFetching && isLoadingBasket) || error) {
            setIsLoadingBasket(false);
        }
    }, [isFetching, error]);

    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);
    const currentBasketItem = useMemo(
        () => (user ? basketData.find(item => item.id === id) : basketProducts.find(item => item.id === id)),
        [basketProducts, id, user, basketData]
    );

    const addToBasketHelper = useAddToBasketHandler();

    const addToBasketHandler = useCallback(() => {
        addToBasketHelper(id, productItem, setIsLoadingBasket);
    }, [basketProducts, basketData, productItem, id]);

    const changeQtyHelper = useChangeQty(productItem);

    const changeQtyHandler = useCallback(
        (value: number) => {
            changeQtyHelper(value, id);
        },
        [basketProducts, user, id]
    );

    return currentBasketItem?.qty !== undefined && currentBasketItem?.qty > 0 ? (
        <div
            css={{
                padding: `${scale(1, true)}px 0`,
                height: scale(6),
                width: activeView === VIEW_OPTIONS.LINES ? scale(20) : '100%',
                backgroundColor: colors.primary,
                borderRadius: 6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                color: '#ffffff',
                [sm]: {
                    height: scale(5),
                },

                alignSelf: activeView === VIEW_OPTIONS.LINES ? 'flex-end' : 'flex-start',
            }}
        >
            {isLoading || isLoadingBasket ? (
                <Loader
                    height={scale(7, true)}
                    width={scale(7, true)}
                    css={{
                        position: 'static',
                        background: 'transparent',
                        width: scale(7, true),
                        height: scale(7, true),
                    }}
                />
            ) : (
                <Counter
                    isBuyBtn
                    name="qty"
                    label=""
                    value={currentBasketItem.qty}
                    max={quantity}
                    onChange={changeQtyHandler}
                />
            )}
        </div>
    ) : (
        <Media query={{ maxWidth: 1023 }} defaultMatches>
            {matches => (
                <Button
                    data-testid={`buy-button-outlineSecondary`}
                    id={`buy-button-outlineSecondary`}
                    theme={matches ? 'outlineSecondary' : 'outlineGrey'}
                    block
                    onClick={addToBasketHandler}
                    size="md"
                    css={{
                        whiteSpace: 'nowrap',

                        [sm]: {
                            height: scale(5),
                            border: `1px solid ${colors.secondary}`,
                            color: colors.secondary,
                        },
                        ':hover': {
                            backgroundColor: `${colors.primary} !important`,
                            color: `${colors.white} !important`,
                        },
                        alignSelf: activeView === VIEW_OPTIONS.LINES ? 'flex-end' : 'flex-start',
                        width: activeView === VIEW_OPTIONS.LINES ? scale(20) : '100%',
                    }}
                >
                    {isLoading || isLoadingBasket ? (
                        <Loader
                            height={scale(7, true)}
                            width={scale(7, true)}
                            css={{
                                position: 'static',
                                background: 'transparent',
                                width: scale(7, true),
                                height: scale(7, true),
                            }}
                        />
                    ) : (
                        'В корзину'
                    )}
                </Button>
            )}
        </Media>
    );
};

export default BasketButton;
